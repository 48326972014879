import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Box, Typography, IconButton, CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import auth from '../auth';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import '../App.css';
import api from '../axiosConfig';

const Form = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [formData, setFormData] = useState({
    nombre: '',
    instagram: '',
    whatsapp: '',
    edad: '',
    email: '', // Nuevo campo de correo electrónico
    entradas: [
      // { tipo: 'Early Bird (2 accesos)', cantidad: 0, precio: 9000 },
      { tipo: 'Entrada General (tanda 3)', cantidad: 0, precio: 8000 },
      { tipo: 'Entrada Vip (tanda 3)', cantidad: 0, precio: 20000 },
      ...(auth.getRole() === 'admin' ? [{ tipo: 'Free', cantidad: 0, precio: 0 }] : []),
    ]
  });
  const [message, setMessage] = useState('');
  const [total, setTotal] = useState(0);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false); // Estado para el preloader

  useEffect(() => {
    // Verificar si el usuario es admin
    const role = auth.getRole();
    setIsAdmin(role === 'admin');

    // Calcular el total cada vez que se actualiza el formData
    const newTotal = formData.entradas.reduce((acc, entrada) => acc + entrada.cantidad * entrada.precio, 0);
    setTotal(newTotal);
  }, [formData]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCountChange = (type, operation) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      entradas: prevFormData.entradas.map((entrada) =>
        entrada.tipo === type
          ? { ...entrada, cantidad: Math.max(0, entrada.cantidad + (operation === 'increment' ? 1 : -1)) }
          : entrada
      )
    }));
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.(com|com\.ar)$/;
    return regex.test(email);
  };

  const validateForm = () => {
    let tempErrors = {};
    if (!formData.nombre) tempErrors.nombre = "El nombre es requerido";
    if (!formData.instagram) tempErrors.instagram = "El Instagram es requerido";
    if (!formData.whatsapp) tempErrors.whatsapp = "El Whatsapp es requerido";
    if (!formData.edad) tempErrors.edad = "La edad es requerida";
    if (!formData.email) {
      tempErrors.email = "El correo electrónico es requerido";
    } else if (!validateEmail(formData.email)) {
      tempErrors.email = "El correo electrónico debe terminar en .com o .com.ar";
    }
    if (!formData.entradas.some(entrada => entrada.cantidad > 0)) tempErrors.entradas = "Debe seleccionar al menos una entrada";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');

    if (!validateForm()) {
      return;
    }

    // Filtrar las entradas con cantidad mayor que 0
    const entradasFiltradas = formData.entradas.filter(entrada => entrada.cantidad > 0);

    // Crear un nuevo objeto de formData con las entradas filtradas
    const formDataFiltrado = {
      ...formData,
      entradas: entradasFiltradas
    };

    try {
      setLoading(true);
      const response = await api.post('/tickets', formDataFiltrado, {
        headers: auth.authHeader()
      });

      setMessage('Tu entrada fue creada (Recorda, enviar el dinero para que se haga el envío de la misma)');
      setFormData({
        nombre: '',
        instagram: '',
        whatsapp: '',
        edad: '',
        email: '',
        entradas: [
          // { tipo: 'Early Bird (2 accesos)', cantidad: 0, precio: 9000 },
          // { tipo: 'Early Bird VIP (2 accesos)', cantidad: 0, precio: 13500 },
          { tipo: 'Entrada General (tanda 3)', cantidad: 0, precio: 8000 },
          { tipo: 'Entrada Vip (tanda 3)', cantidad: 0, precio: 20000 },
          ...(isAdmin ? [{ tipo: 'Free', cantidad: 0, precio: 0 }] : []),
        ]
      });
      setTotal(0);
    } catch (error) {
      setMessage('Error al crear el ticket');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', maxWidth: '500px' }}>
        <Typography className='session-font' variant="h4" gutterBottom style={{ fontWeight: 'bold' }}>
          Entradas - Session Beach 🥂
        </Typography>
        {!loading && (
          <Button component={Link} to="/menu" variant="contained" color="primary" sx={{ marginBottom: '20px' }}>
            Menu
          </Button>
        )}
        {message && <Typography style={{ textAlign: 'center', fontWeight: 'bold' }} variant="subtitle1" color="green" gutterBottom>{message}</Typography>}
        {loading && <CircularProgress />}
        {loading && <Typography variant="body1" style={{ fontSize: "12px", fontWeight: 'bold' }} sx={{ textTransform: 'capitalize', fontWeight: 'bold' }}>Creando entrada...</Typography>}
        {!loading && (
          <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
            <TextField
              name="nombre"
              label="Nombre"
              value={formData.nombre}
              onChange={handleChange}
              fullWidth
              error={!!errors.nombre}
              helperText={errors.nombre}
            />
            <TextField
              name="instagram"
              label="Instagram"
              value={formData.instagram}
              onChange={handleChange}
              fullWidth
              error={!!errors.instagram}
              helperText={errors.instagram}
            />
            <TextField
              name="whatsapp"
              label="Whatsapp (ejemplo: 3534231033)"
              value={formData.whatsapp}
              onChange={handleChange}
              fullWidth
              error={!!errors.whatsapp}
              helperText={errors.whatsapp}
            />
            <TextField
              name="edad"
              label="Edad"
              value={formData.edad}
              onChange={handleChange}
              fullWidth
              error={!!errors.edad}
              helperText={errors.edad}
            />
            <TextField
              name="email"
              label="Correo Electrónico"
              value={formData.email}
              onChange={handleChange}
              fullWidth
              error={!!errors.email}
              helperText={errors.email}
            />

            {formData.entradas.map((entrada) => (
              <Box key={entrada.tipo} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px', }}>
                <Typography variant="body1" style={{ fontSize: "12px", fontWeight: '500' }} sx={{ textTransform: 'capitalize' }}>{entrada.tipo} (${entrada.precio})</Typography>
                <Box>
                  <IconButton onClick={() => handleCountChange(entrada.tipo, 'decrement')}>
                    <RemoveIcon />
                  </IconButton>
                  <Typography variant="body1" sx={{ margin: '0 10px', display: 'inline-block' }}>{entrada.cantidad}</Typography>
                  <IconButton onClick={() => handleCountChange(entrada.tipo, 'increment')}>
                    <AddIcon />
                  </IconButton>
                </Box>
              </Box>
            ))}

            {errors.entradas && <Typography color="error">{errors.entradas}</Typography>}

            <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>
              Total: ${total}
            </Typography>

            <Button type="submit" variant="contained" color="success">
              Enviar
            </Button>
          </form>
        )}
      </Box>
    </Container>
  );
};

export default Form;
