// src/axiosConfig.js
import axios from 'axios';
import auth from './auth';

const api = axios.create({
  baseURL: 'https://api.ticketspace.com.ar/api',
  // baseURL: 'http://localhost:8080/api',
});

// Interceptor de respuesta para manejar errores de autenticación
api.interceptors.response.use(
  response => response,
  error => {
    const { response } = error;
    if (response && response.status === 401) {
      auth.logout();
      window.location.href = '/'; // Redirigir a la página de inicio de sesión
    }
    return Promise.reject(error);
  }
);

export default api;
