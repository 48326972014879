import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextField, Container, Typography, Box } from '@mui/material';
import auth from '../auth';
import '../App.css';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      // Convertir los valores a minúsculas antes de enviarlos
      await auth.login(username.toLowerCase(), password.toLowerCase());
      navigate('/menu');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Container>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography className="session-font" variant="h4" style={{ fontWeight: 'bold' }} gutterBottom>
          Inicio de sesión - Session Beach 🥂
        </Typography>
        {error && <Typography color="error">{error}</Typography>}
        <Box width="100%" maxWidth="400px" mt={2}>
          <TextField
            label="Usuario (enviado por whatsapp)"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Contraseña (enviada por whatsapp)"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Button variant="contained" color="primary" onClick={handleLogin} fullWidth>
            Ingresar
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
