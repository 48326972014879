import React from 'react';
import Table from './Table';
import auth from '../auth';

const MyTickets = () => {
  const username = auth.getUsername();

  return (
    <div>
      <Table username={username} />
    </div>
  );
};

export default MyTickets;
