import React, { useEffect, useState } from 'react';
import api from '../axiosConfig';
import { Link, Navigate } from 'react-router-dom';
import { Container, Box, Typography, Table as MuiTable, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TablePagination, Chip } from '@mui/material';
import auth from '../auth';

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [tickets, setTickets] = useState([]);
  const [users, setUsers] = useState([]);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [total, setTotal] = useState(0);
  const [comision, setComision] = useState(0);
  const [entradasPorTipo, setEntradasPorTipo] = useState({});
  const [error, setError] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(true);

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await api.get(`/tickets/all`, {
          headers: auth.authHeader(),
        });
        const { tickets, entradasPorTipo } = response.data;
        setTickets(tickets);
        setFilteredTickets(tickets);
        setEntradasPorTipo(entradasPorTipo);
        calculateTotals(tickets);
      } catch (error) {
        setError(error.response?.data?.message || 'Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    const fetchUsers = async () => {
      try {
        const response = await api.get('/users', {
          headers: auth.authHeader(),
        });
        setUsers(response.data);
      } catch (error) {
        setError(error.response?.data?.message || 'Error fetching users');
      }
    };

    const checkSendButtonStatus = () => {
      const today = new Date();
      const disableUntilDate = new Date('2024-07-19');
      setIsSendButtonDisabled(today < disableUntilDate);
    };

    fetchTickets();
    fetchUsers();
    checkSendButtonStatus();
  }, []);

  const calculateTotals = (tickets) => {
    const total = tickets.reduce((sum, ticket) => {
      return sum + ticket.entradas.reduce((entrySum, entrada) => {
        return entrySum + entrada.precio;
      }, 0);
    }, 0);

    const comision = total * 0.15;

    setTotal(total);
    setComision(comision);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSendAll = async (ticketId) => {
    try {
      const response = await api.post(`/tickets/send/${ticketId}`, {}, {
        headers: auth.authHeader(),
      });
      alert('Todas las entradas enviadas exitosamente a WhatsApp');
    } catch (error) {
      alert('Error al enviar las entradas');
    }
  };

  const handleFilterByUser = (username) => {
    const filtered = tickets.filter(ticket => ticket.createdBy === username);
    setFilteredTickets(filtered);
    calculateTotals(filtered);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!auth.isLoggedIn()) {
    return <Navigate to="/" />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <Container>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
        <Typography variant="h4" gutterBottom>
          Dashboard
        </Typography>
        <Button component={Link} to="/menu" variant="contained" color="primary" sx={{ marginBottom: '20px' }}>
          Menu
        </Button>
        <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 1, marginBottom: 2 }}>
          {users.map(user => (
            <Chip
              key={user._id}
              label={user.username}
              onClick={() => handleFilterByUser(user.username)}
            />
          ))}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: 2 }}>
          <Typography style={{ fontWeight: 'bold' }} variant="h6">Total: ${total.toFixed(2)}</Typography>
          <Typography style={{ fontWeight: 'bold' }} variant="h6">Comisión: ${comision.toFixed(2)}</Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 1, marginBottom: 2 }}>
          {Object.entries(entradasPorTipo).map(([tipo, cantidad]) => (
            <Chip style={{fontWeight:'bold'}}
              key={tipo}
              label={`${tipo}: ${cantidad}`}
            />
          ))}
        </Box>
        <TableContainer component={Paper}>
          <MuiTable>
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>Instagram</TableCell>
                <TableCell>Whatsapp</TableCell>
                <TableCell>Edad</TableCell>
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredTickets.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(ticket => (
                <TableRow key={ticket._id}>
                  <TableCell>{ticket.nombre}</TableCell>
                  <TableCell>{ticket.instagram}</TableCell>
                  <TableCell>{ticket.whatsapp}</TableCell>
                  <TableCell>{ticket.edad}</TableCell>
                  <TableCell>
                  {[...new Map(ticket.entradas.map(entrada => [entrada.tipo, entrada])).values()]
                      .map((entrada, index) => (
                        <Typography key={index}>{entrada.tipo}: x{entrada.cantidad}</Typography>
                    ))}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleSendAll(ticket._id)}
                      sx={{ marginBottom: '10px' }}
                      disabled={isSendButtonDisabled}
                    >
                      Disponible el 19/07/2024
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </MuiTable>
          <TablePagination
            component="div"
            count={filteredTickets.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 50]}
          />
        </TableContainer>
      </Box>
    </Container>
  );
};

export default Dashboard;
