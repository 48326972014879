import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Box, Typography, Button } from '@mui/material';

const TicketEscaneado = () => {
  const navigate = useNavigate();

  return (
    <Container sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h4" color="red" gutterBottom style={{fontWeight:'bold'}}>
         Ticket invalido ❌
        </Typography>
        <Button variant="contained" color="primary" onClick={() => navigate('/scan-qr')}>
          Volver a Escanear
        </Button>
      </Box>
    </Container>
  );
};

export default TicketEscaneado;
