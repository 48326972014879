import React, { useEffect, useState } from 'react';
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Box,
  Container,
  Typography,
  Button,
  Chip
} from '@mui/material';
import api from '../axiosConfig';
import auth from '../auth';
import { Link } from 'react-router-dom';

const Table = ({ username }) => {
  const [tickets, setTickets] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [comision, setComision] = useState(0);
  const [error, setError] = useState('');
  const [entradasPorTipo, setEntradasPorTipo] = useState({});

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const token = auth.getToken();
        const url = username
          ? `/tickets/user/${username}`
          : '/tickets';
        const response = await api.get(url, {
          headers: { Authorization: `Bearer ${token}` }
        });
        const tickets = response.data;
        setTickets(tickets);
        setLoading(false);
        calculateTotals(tickets);
        countEntriesByType(tickets);
      } catch (error) {
        setError('Error al obtener los tickets');
        setLoading(false);
      }
    };

    fetchTickets();
  }, [username]);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const calculateTotals = (tickets) => {
    const total = tickets.reduce((sum, ticket) => {
      return sum + ticket.entradas.reduce((entrySum, entrada) => {
        return entrySum + entrada.precio;
      }, 0);
    }, 0);

    const comision = total * 0.15;

    setTotal(total);
    setComision(comision);
  };

  const countEntriesByType = (tickets) => {
    const counts = {};
    tickets.forEach(ticket => {
      ticket.entradas.forEach(entrada => {
        if (!counts[entrada.tipo]) {
          counts[entrada.tipo] = 0;
        }
        counts[entrada.tipo] += 1;
      });
    });
    setEntradasPorTipo(counts);
  };

  const filteredTickets = tickets.filter((ticket) =>
    Object.values(ticket).some((value) => value.toString().toLowerCase().includes(search.toLowerCase()))
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <Container sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box sx={{ width: '100%', maxWidth: '1200px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 2 }}>
          <Typography variant="h4" gutterBottom>
            Lista de Tickets
          </Typography>
          <TextField
            label="Buscar"
            variant="outlined"
            value={search}
            onChange={handleSearchChange}
            sx={{ width: '100%', maxWidth: '600px', marginBottom: 2 }}
          />
          <Button component={Link} to="/menu" variant="contained" color="primary" sx={{ marginBottom: '20px' }}>
            Menu
          </Button>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: 2 }}>
            <Typography style={{ fontWeight: 'bold' }} variant="h6">Total: ${total.toFixed(2)}</Typography>
            <Typography style={{ fontWeight: 'bold' }} variant="h6">Comisión: ${comision.toFixed(2)}</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 1, marginBottom: 2 }}>
            {Object.entries(entradasPorTipo).map(([tipo, cantidad]) => (
              <Chip key={tipo} label={`${tipo}: ${cantidad}`} />
            ))}
          </Box>
        </Box>
        <TableContainer>
          <MuiTable>
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>Instagram</TableCell>
                <TableCell>Whatsapp</TableCell>
                <TableCell>Edad</TableCell>
                <TableCell>Entradas</TableCell>
                <TableCell>Creado por</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredTickets.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((ticket) => (
                <TableRow key={ticket._id}>
                  <TableCell>{ticket.nombre}</TableCell>
                  <TableCell>{ticket.instagram}</TableCell>
                  <TableCell>{ticket.whatsapp}</TableCell>
                  <TableCell>{ticket.edad}</TableCell>
                  <TableCell>
                    {[...new Map(ticket.entradas.map(entrada => [entrada.tipo, entrada])).values()]
                      .map((entrada, index) => (
                        <Typography style={{ fontWeight: 'bold' }} key={index}>{entrada.tipo}: x{entrada.cantidad}</Typography>
                      ))}
                  </TableCell>
                  <TableCell>{ticket.createdBy}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </MuiTable>
        </TableContainer>
        <TablePagination
          component="div"
          count={filteredTickets.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage="Filas por página"
        />
      </Box>
    </Container>
  );
};

export default Table;
