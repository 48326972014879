import axios from 'axios';

const API_URL = 'https://api.ticketspace.com.ar/api/users';
// const API_URL = 'http://localhost:8080/api/users';

const login = async (username, password) => {
  try {
    const response = await axios.post(`${API_URL}/login`, { username, password });
    const { token, role } = response.data;
    localStorage.setItem('token', token);
    localStorage.setItem('role', role);
    localStorage.setItem('username', username); // Almacenar el nombre de usuario
    return { token, role };
  } catch (error) {
    throw new Error('Invalid username or password');
  }
};

const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('role');
  localStorage.removeItem('username'); // Remover el nombre de usuario
  window.location.href = '/'; // Redirigir a la página de inicio de sesión
};

const getToken = () => localStorage.getItem('token');

const getRole = () => localStorage.getItem('role');

const getUsername = () => localStorage.getItem('username'); // Obtener el nombre de usuario

const isLoggedIn = () => !!localStorage.getItem('token');

const authHeader = () => {
  const token = getToken();
  if (token) {
    return { Authorization: `Bearer ${token}` };
  }
  return {};
};

export default {
  login,
  logout,
  getToken,
  getRole,
  getUsername, // Exportar la nueva función
  isLoggedIn,
  authHeader,
};
