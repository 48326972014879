import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Box, Typography, Button } from '@mui/material';

const TicketAprobado = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const entrada = location.state || {};

  return (
    <Container sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography color="green" variant="h4" gutterBottom style={{fontWeight:'bold'}}>
          Ticket Aprobado ✅
        </Typography>
        <Typography variant="body1" gutterBottom>
          Nombre: {entrada.nombre}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Tipo de entrada: {entrada.tipo}
        </Typography>
        <Button variant="contained" color="primary" sx={{ marginTop: '20px' }} onClick={() => navigate('/scan-qr')}>
          Escanear Otro QR
        </Button>
      </Box>
    </Container>
  );
};

export default TicketAprobado;
