import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Form from './components/Form';
import MyTickets from './components/MyTicketsTable';
import ScanQR from './components/ScanQR';
import TicketAprobado from './components/TicketAprobado'; // Importa el nuevo componente
import TicketEscaneado from './components/TicketEscaneado'; // Importa el nuevo componente
import auth from './auth';
import Login from './components/Login';
import Menu from './components/Menu';

const PrivateRoute = ({ element, ...rest }) => {
  return auth.isLoggedIn() ? element : <Navigate to="/" />;
};

const AdminRoute = ({ element, ...rest }) => {
  return auth.getRole() === 'admin' ? element : <Navigate to="/" />;
};

const PublicRoute = ({ element, ...rest }) => {
  const role = auth.getRole();
  return (role === 'publica' || role === 'admin') ? element : <Navigate to="/" />;
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/menu" element={<PublicRoute element={<Menu />} />} />
        <Route path="/dashboard" element={<AdminRoute element={<Dashboard />} />} />
        <Route path="/form" element={<PublicRoute element={<Form />} />} />
        <Route path="/my-tickets" element={<PrivateRoute element={<MyTickets />} />} />
        <Route path="/scan-qr" element={<PrivateRoute element={<ScanQR />} />} />
        <Route path="/ticket-aprobado" element={<PrivateRoute element={<TicketAprobado />} />} />
        <Route path="/ticket-escaneado" element={<PrivateRoute element={<TicketEscaneado />} />} />
      </Routes>
    </Router>
  );
};

export default App;
