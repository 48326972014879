import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Box, Container } from '@mui/material';
import auth from '../auth';
import '../App.css';

const Menu = () => {
  const navigate = useNavigate();
  const isAdmin = auth.getRole() === 'admin';

  const handleLogout = () => {
    localStorage.clear();
    navigate('/');
  };

  const buttonStyle = {
    width: '200px',  // Define un ancho fijo para todos los botones
    marginBottom: '10px',
  };

  return (
    <Container sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h1 className="session-font" >Session Beach 🥂</h1>
        <Button component={Link} to="/form" variant="contained" color="primary" sx={buttonStyle}>
          Generar Entradas
        </Button>
        {isAdmin && (
          <>
            <Button component={Link} to="/dashboard" variant="contained" color="primary" sx={buttonStyle}>
              Dashboard
            </Button>
            <Button component={Link} to="/scan-qr" variant="contained" color="primary" sx={buttonStyle}>
              Escanear QR
            </Button>
          </>
        )}
        <Button component={Link} to="/my-tickets" variant="contained" color="primary" sx={buttonStyle}>
          Mis entradas
        </Button>
        <Button onClick={handleLogout} variant="contained" color="secondary" sx={buttonStyle}>
          Desconectarse
        </Button>
      </Box>
    </Container>
  );
};

export default Menu;
