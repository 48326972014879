import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Box, Typography, Button } from '@mui/material';
import QrScanner from 'react-qr-scanner';
import axios from 'axios';
import auth from '../auth';

const ScanQR = () => {
  const [error, setError] = useState('');
  const [scanning, setScanning] = useState(true);
  const [useFrontCamera, setUseFrontCamera] = useState(false);
  const navigate = useNavigate();
  const timeoutRef = useRef(null);

  useEffect(() => {
    // Detect Safari
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      setUseFrontCamera(false);  // Ensure camera is set to rear for Safari
    }
  }, []);

  const handleScan = async (data) => {
    if (data && scanning) {
      setScanning(false);
      try {
        const token = auth.getToken();
        const response = await axios.post(data.text, {}, {
          headers: { Authorization: `Bearer ${token}` }
        });
        if (response.data.message === 'Entrada marked as scanned') {
          navigate('/ticket-aprobado', { state: response.data.entrada });
        } else if (response.data.message === 'Entrada already scanned') {
          navigate('/ticket-escaneado', { state: response.data.entrada });
        } else {
          setError('Error al activar la entrada');
        }
      } catch (error) {
        if (error.response && error.response.data.message === 'Entrada already scanned') {
          navigate('/ticket-escaneado', { state: error.response.data.entrada });
        } else {
          setError('Error al activar la entrada');
        }
      } finally {
        timeoutRef.current = setTimeout(() => {
          setScanning(true);
          setError('');
        }, 2000); // Reinicia el escaneo después de 2 segundos
      }
    }
  };

  const handleError = (err) => {
    console.error(err);
    setError('Error al acceder a la cámara. Por favor, asegúrate de haber concedido los permisos necesarios y de que ninguna otra aplicación esté usando la cámara.');
  };

  const toggleCamera = () => {
    setUseFrontCamera((prev) => !prev);
  };

  const previewStyle = {
    height: 600,
    width: 400,
  };

  useEffect(() => {
    return () => clearTimeout(timeoutRef.current);
  }, []);

  return (
    <Container sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Escanear Código QR
        </Typography>
        <QrScanner
          delay={300}
          style={previewStyle}
          onError={handleError}
          onScan={handleScan}
          facingMode={useFrontCamera ? 'user' : 'environment'} // Default to 'environment'
        />
        <Button variant="contained" color="primary" sx={{ marginTop: '20px' }} onClick={() => navigate('/menu')}>
          Volver al Menú
        </Button>
        <Button variant="contained" color="secondary" sx={{ marginTop: '20px' }} onClick={toggleCamera}>
          {useFrontCamera ? 'Usar cámara trasera' : 'Usar cámara frontal'}
        </Button>
        {error && <Typography variant="body1" color="error" sx={{ marginTop: '20px' }}>{error}</Typography>}
      </Box>
    </Container>
  );
};

export default ScanQR;
